import * as React from 'react';
import { Table, TableBody, TableCell, TableRow, Typography, Select, SelectChangeEvent, FormControl, InputLabel, MenuItem, ListItemText } from '@mui/material';
import Dinero from 'dinero.js';
import UserCartStore from '@af-common/UserData/_stores/UserCartStore';
import { Trans } from 'react-i18next';
import useIsSale from '_hooks/useIsSale';

export type Props = {
	cart: UserCartStore
	updateShippingType?: Function
	noEdit?: boolean
	lang?: string
	currency?: string
}

const T = ({ children, ...otherProps }: any) => <Trans ns="cart" {...otherProps}>{children}</Trans>

export default function CartSummary(props: Props) {
	const onShippingTypeChange = (e: SelectChangeEvent<number>) => {
		const value = e.target.value;

		if (value == props.cart.get('id_shipping_type')) return;

		const fd = new FormData();
		fd.append('type', '' + value);
		props.updateShippingType(fd);
	}

	const isSale = useIsSale()

	return <div className="cart-summary-wrapper">
		<div className="cart-summary-item">
			<h6><T>Subtotal</T></h6>
			<span>{Dinero(props.cart.get('subtotal').toObject()).toFormat()}</span>
		</div>
		<div className="cart-summary-item">
			<h6 style={!props.cart.get('pickup') && props.cart.get('id_shipping_address') ? { border: 0 } : {}}><T>Shipping</T></h6>
			<span style={!props.cart.get('pickup') && props.cart.get('id_shipping_address') ? { border: 0 } : {}}>{Dinero(props.cart.get('shipping').toObject()).toFormat()}</span>
			{!props.noEdit && !props.cart.get('pickup') && props.cart.get('id_shipping_address') &&
				<div className="shipping-options">
					<FormControl>
						<Select
							labelId="shipping-type-label"
							id="shipping-type"
							value={props.cart.get('id_shipping_type')}
							onChange={onShippingTypeChange}
						>
							{props.cart.get('availableShippingTypes').entrySeq().map(([id, type]) => {
								return <MenuItem key={String(id)} value={+type.get('id')}>
									<ListItemText primary={type.get('name')} />
								</MenuItem>
							})}
						</Select>
					</FormControl>
				</div>
			}
		</div>

		{Dinero(props.cart.get('discount').toObject()).getAmount() > 0 ? <>
			{isSale ? <div className="cart-summary-item" style={{
				background: '#151744',
				border: '0',
				padding: '15px',
				margin: '15px -15px',
				borderRadius: '20px',
			}}>
				<h6 style={{
					width: '100%',
					fontSize: '18px',
					color: '#fff',
					marginBottom: '5px',
				}}>BOXING DAY 2024</h6>
				<h6 style={{ color: '#fff' }}><T>Discount</T></h6>
				<span style={{ color: '#fff' }}>{Dinero(props.cart.get('discount').toObject()).multiply(-1).toFormat()}</span>
			</div> : <div className="cart-summary-item">
				<h6><T>Discount</T></h6>
				<span>{Dinero(props.cart.get('discount').toObject()).multiply(-1).toFormat()}</span>
			</div>}
		</> : null}

		<div className="cart-summary-item">
			<h6><T>Taxes</T></h6>
			<span>{Dinero(props.cart.get('taxes').toObject()).toFormat()}</span>
		</div>
		<div className="cart-summary-item">
			<h6><T>Total</T></h6>
			<span>{Dinero(props.cart.get('total').toObject()).toFormat()}</span>
		</div>
	</div>
}