import React, { useMemo, useState } from 'react';
import { Dialog, DialogContent, DialogActions, Button, AppBar, Container, Toolbar, IconButton, Icon, Fade } from '@mui/material';
import SidebarContainer from '../../Common/_containers/SidebarContainer';
import { Link } from 'react-router-dom';
import { useTranslation, withTranslation, WithTranslation } from "react-i18next";
import { Desktop, Mobile } from '@af-common/Common/_components/Devices';
import { useSelector } from 'react-redux';
import WebsiteStore from 'WebsiteStore';
import UserNav from './UserNav';
import useIsSale from '_hooks/useIsSale';

export type Props = {
	withMenu?: boolean
	light?: boolean
	borderColor?: 'color1' | 'color2' | 'none'
}

export default function Header(props: Props) {
	const [t] = useTranslation();
	const [tR] = useTranslation('routes');

	const [menuOpen, setMenuOpen] = useState(false)
	const [fabricNavOpen, setFabricNavOpen] = useState(false)
	const [printingNavOpen, setPrintingNavOpen] = useState(false)
	const [headlineDetailsOpened, setHeadlineDetailsOpened] = useState(false)

	const onHeadlineDetailsOpen = () => {
		setHeadlineDetailsOpened(true)
	}

	const onHeadlineDetailsClose = () => {
		setHeadlineDetailsOpened(false)
	}

	const isSale = useIsSale()

	return <>
		<div id="headline-banner">
			<Container>
				{t('Free shipping on Sample packs for Canada and US. Sign up and get 50% OFF your first meter of fabric!')} <span className="details-link" onClick={onHeadlineDetailsOpen}>{t('View details')}</span>
				{ /*isSale ? t('BOXING DAY 2024! 35% OFF THE REGULAR PRICE OF ALL FABRICS!')} <span className="details-link" onClick={onHeadlineDetailsOpen}>{t('View details')}</span> : null */}
			</Container>
		</div>
		<AppBar id="nav" position="static" className={(props.light ? "nav--light" : "") + (props.borderColor ? ' border-' + props.borderColor : '')}>
			<Container>
				<Toolbar disableGutters className="top-nav">
					<Mobile>
						<IconButton className="menu-button" onClick={() => setMenuOpen(true)}>
							<Icon>menu</Icon>
						</IconButton>
					</Mobile>
					<Link to={tR('/')} className="brand brand-small">
						<img src="static/img/logo.svg" alt="Art Fabrics" width="220" height="40" />
					</Link>
					<Desktop>
						<ul className="left-nav">
							<li><Link className="link link--bold" to={tR('/create')}>{t('Start Designing')}</Link></li>
							<li onMouseOver={() => setFabricNavOpen(true)}
								onMouseOut={() => setFabricNavOpen(false)}
							>
								<Link className="link" to={tR('/fabrics')}>{t('Our fabrics')}</Link>
								<Fade in={fabricNavOpen}>
									<ul>
										<li><Link className="link" to={tR('/order-sample-pack')}>{t('Order sample pack')}</Link></li>
										<li><Link className="link" to={tR('/fabrics')}>{t('All fabrics & Prices')}</Link></li>
										<li><Link className="link" to={tR('/fabrics/cotton')}>{t('Cotton')}</Link></li>
										<li><Link className="link" to={tR('/fabrics/silk')}>{t('Silk')}</Link></li>
										<li><Link className="link" to={tR('/fabrics/hemp')}>{t('Hemp')}</Link></li>
										<li><Link className="link" to={tR('/fabrics/polyester')}>{t('Polyester')}</Link></li>
										<li><Link className="link" to={tR('/fabrics/linen')}>{t('Linen')}</Link></li>
										<li><Link className="link" to={tR('/fabrics/modal')}>{t('Modal')}</Link></li>
										<li><Link className="link" to={tR('/fabrics/cashmere')}>{t('Cashmere')}</Link></li>
										<li><Link className="link" to={tR('/fabrics/vegan-leather')}>{t('Vegan Leather')}</Link></li>
									</ul>
								</Fade>
							</li>
							<li
								onMouseOver={() => setPrintingNavOpen(true)}
								onMouseOut={() => setPrintingNavOpen(false)}
							>
								<Link className="link" to={tR('/printing-methods/reactive')}>{t('Resources')}</Link>
								<Fade in={printingNavOpen}>
									<ul>
										<li><Link className="link" to={tR('/printing-methods/reactive')}>{t('Reactive printing')}</Link></li>
										<li><Link className="link" to={tR('/printing-methods/sublimation')}>{t('Sublimation printing')}</Link></li>
										<li><Link className="link" to={tR('/about-us')}>{t('About us')}</Link></li>
										<li><Link className="link" to={tR('/contact-us')}>{t('Contact us')}</Link></li>
									</ul>
								</Fade>
							</li>
							<li><a href="https://blog.artfabrics.com/" target="_blank" className="link">{t('Blog')}</a></li>
						</ul>
					</Desktop>
					<UserNav showCurrency={true} />
				</Toolbar>
			</Container>
		</AppBar>
		<Mobile>
			<SidebarContainer temporary menuOpen={menuOpen} onMenuClose={() => setMenuOpen(false)} />
		</Mobile>

		<Dialog className="main-dialog-woo" open={headlineDetailsOpened} onClose={onHeadlineDetailsClose}>
			<DialogContent>
				<Button className="bye-dialog" autoFocus onClick={onHeadlineDetailsClose} color="primary">✕</Button>
				{isSale ? <>
					<h3><strong>BOXING DAY 2024</strong><br /> {t('35% OFF regular price of ALL FABRICS')}</h3>
					<ul>
						<li>{t('From December 26th to 29th')}</li>
						<li>{t('Will be applied automatically to your order at Checkout')}</li>
						<li>{t('Replaces the normal discounts for higher quantities of meters.')}</li>
					</ul>
				</> : <>
					<h3>{t('50% OFF your first meter of fabric')}</h3>
					<ul>
						<li>{t('Will be applied automatically to your order at Checkout')}</li>
						<li>{t('You must sign up and validate your email')}</li>
						<li>{t('Only available once per account')}</li>
					</ul>
					<h4>{t('Free shipping on Sample packs')}</h4>
					<ul>
						<li>{t('Will be applied automatically to your order at Checkout')}</li>
						<li>{t('Only available for Canada and United States')}</li>
						<li>{t('Only applicable to orders of a single Sample pack')}</li>
					</ul>
				</>}
			</DialogContent>
		</Dialog>
	</>
}