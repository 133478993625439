import AwareComponent from '@af-common/Common/_components/AwareComponent';
import Loader from '@af-common/Common/_components/Loader';
import { CartStatus } from '@af-common/UIData/_actions/UIDataActions';
import UserCartStore, { RegularItem } from '@af-common/UserData/_stores/UserCartStore';
import { Button, Container, Grid, styled } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import TopBanner from '../../Common/_components/TopBanner';
import CartContentContainer from '../_containers/CartContentContainer';
import { Helmet } from 'react-helmet';
import Dinero from 'dinero.js';
import { Fabric } from '@af-common/UIData/_data/Fabrics';
import DineroFactory from 'dinero.js';
import { useSelector } from 'react-redux';
import WebsiteStore from 'WebsiteStore';
import useIsSale from '_hooks/useIsSale';

const SignUpNow = styled('div')(({ theme }) => ({
	padding: '30px 0',
	display: 'flex',
	alignItems: 'center',
	'img': {
		maxWidth: '150px',
	},
	'h2': {
		fontSize: '26px',
		margin: '0',
		color: '#F9AA92',
		[theme.breakpoints.down('md')]: {
			fontSize: '20px',
		},
	},
	'p': {
		fontSize: '20px',
		margin: '5px 0 15px 0',
		fontWeight: '500',
		[theme.breakpoints.down('md')]: {
			fontSize: '16px',
		},
	}
}))

export type Props = {
	status?: CartStatus
	setStatus?: (status: CartStatus) => {}
	getUserCart?: Function
	loggedIn?: boolean
	cart?: UserCartStore
	cart_loading?: boolean
	saveCartAddress?: Function
	fabrics?: Immutable.OrderedMap<number, Fabric>
};

const T = ({ children, ...otherProps }: any) => <Trans ns="cart" {...otherProps}>{children}</Trans>

export default function Cart(props: Props) {
	const [tR] = useTranslation('routes')
	const navigate = useNavigate()
	const loggedIn = useSelector((state: WebsiteStore) => state.get('userData').get('loggedIn'))

	const goToCheckout = useCallback(() => {
		if (!props.cart) return;

		const subtotal = Dinero(props.cart.get('subtotal').toObject());
		const discount = Dinero(props.cart.get('discount').toObject());
		const value = subtotal.subtract(discount).toUnit();
		const currency = subtotal.getCurrency();
		let num_items = 0;
		let contents: { id: string, quantity: number }[] = [];
		let content_ids: string[] = [];
		let items: {
			item_id: string,
			item_name: string,
			price: number,
			quantity: number,
		}[] = [];


		props.cart.get('items').forEach((item) => {
			num_items += item.get('quantity');
			let id = item.get('type');
			let name: string = item.get('type');

			if (item instanceof RegularItem) {
				const fabricName = props.fabrics?.get(item.get('fabric').get('id_fabric'))?.get('name');
				const fabricSize = props.fabrics?.get(item.get('fabric').get('id_fabric'))?.get('sizes').get(item.get('fabric').get('id_size'))?.get('name');
				id += '-' + item.get('fabric').get('id_fabric') + '-' + item.get('fabric').get('id_size');
				name = 'Custom Fabric: ' + fabricName + ' | ' + fabricSize;
			}

			contents.push({
				id: id,
				quantity: item.get('quantity'),
			});
			content_ids.push(id);

			items.push({
				item_id: id,
				item_name: name,
				price: Dinero(item.get('price').toObject()).toUnit(),
				quantity: item.get('quantity'),
			})
		});

		window.fbq('track', 'InitiateCheckout', {
			content_ids: content_ids,
			value: value,
			num_items: num_items,
			currency: currency,
			contents: contents,
		});

		window.gtag('event', 'begin_checkout', {
			currency: currency,
			value: value,
			items: items,
		});

		navigate(tR('/cart/checkout'));
	}, [props.cart]);

	useEffect(() => {
		props.getUserCart();
	}, [])

	const isSale = useIsSale()

	const contentStyle = props.cart_loading ? { opacity: 0.5, pointerEvents: 'none' as 'none' } : {};
	return <>
		<Helmet>
			<title>Cart</title>
		</Helmet>

		<Container className="cart-page" maxWidth="lg" style={contentStyle}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<h1><T>Your Cart</T></h1>
				</Grid>
				{props.cart ? <Grid item xs={12} md={8} className="cart-page--orders">
					{props.cart && props.cart.items && props.cart.items.count() ? <>

					</> : props.loggedIn ? <>
						<span className="cart-page--empty"><T>Your cart is empty.</T></span>
						<span className="cart-page--empty"><T>Add a product from <Link to={tR('/account/patterns')}>Your patterns</Link>.</T></span>
						<span className="cart-page--empty"><T>Or <Link to={tR('/create')}>Create a new one</Link>!</T></span>
					</> : <>
						<span className="cart-page--empty"><T>Your cart is empty.</T></span>
						<span className="cart-page--empty"><T>To add a product, start by <Link to={tR('/create')}>Creating it</Link>!</T></span>
					</>
					}
					{props.cart.items?.count() > 0 ? <div>
						<CartContentContainer cart={props.cart} subtotal={true} editable={true} />
					</div> : null}

					{!loggedIn ? <SignUpNow>
						<img src="/static/img/cart/fiftyofficon.svg" alt='<T>50% off</T>' />
						<div>
							<h2><T>Haven't signed up yet?</T></h2>
							<p><T>Sign up now and get 50% off your first meter of fabric.</T></p>
							<Link to={tR('/auth/signup')}><Button color="primary" className="light"><T>Sign up now</T></Button></Link>
						</div>
					</SignUpNow> : null}
				</Grid> : null}
				{props.cart?.items?.count() > 0 ? <Grid item xs={12} md={4}>
					<div className="cart-summary">
						<div className="cart-summary-box">
							<div className="cart-summary-item cart-subtotal">
								<h6><T>Subtotal</T></h6>
								<span>{DineroFactory(props.cart.get('subtotal').toObject()).toFormat()}</span>
							</div>
							<div className="cart-summary-item cart-shipping">
								<h6><T>Shipping</T></h6>
								<span className="calculated"><T>Calculated at<br />checkout</T></span>
							</div>
							{DineroFactory(props.cart.get('discount').toObject()).getAmount() > 0 ? <>
								{isSale ? <div className="cart-summary-item cart-discount">
									<div className="cart-summary-item" style={{
										background: '#151744',
										border: '0',
										padding: '15px',
										margin: '15px -15px',
										borderRadius: '20px',
									}}>
										<h6 style={{
											width: '100%',
											fontSize: '18px',
											color: '#fff',
											marginBottom: '5px',
										}}>BOXING DAY 2024</h6>
										<h6 style={{ color: '#fff' }}><T>Discount</T></h6>
										<span style={{ color: '#fff' }}>{DineroFactory(props.cart.get('discount').toObject()).multiply(-1).toFormat()}</span>
									</div>
								</div> : <div className="cart-summary-item cart-discount">
									<h6><T>Discount</T></h6>
									<span className="calculated">{DineroFactory(props.cart.get('discount').toObject()).multiply(-1).toFormat()}</span>
								</div> }
							</> : null}
							<div className="cart-summary-item cart-taxes">
								<h6><T>Taxes</T></h6>
								<span className="calculated"><T>Calculated at<br />checkout</T></span>
							</div>
							<div className="cart-summary-item cart-total">
								<h6><T>Total</T></h6>
								<span className="calculated"><T>Calculated at<br />checkout</T></span>
							</div>
						</div>
						<Button color="primary" className="dark" onClick={goToCheckout}><T>Checkout</T></Button>
						{ /* <p style={{
								textAlign: 'center',
								color: '#c96142',
								fontSize: '16px',
							 }}><T>Production time is longer than usual due to the upcoming holiday closure. We will be closed April 7-10. Please expect longer production time over the next 2 weeks.</T></p> */ }

					</div>
				</Grid> : null}
			</Grid>
			{props.cart_loading && <Loader noModal />}
		</Container>
	</>
}