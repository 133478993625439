import React, { useEffect, useMemo, useRef, useState } from 'react';
import Footer from '../Common/_components/Footer';
import Header from '../Common/_components/Header';
import { Grid, Container, Button, styled } from '@mui/material';
import config from '../../../../config/config.json';
import { Link } from 'react-router-dom';
import makeCancellablePromise, { CancellablePromise } from '@af-common/Common/Utils/CancellablePromise';
import { Trans, useTranslation } from 'react-i18next';
import TranslationProvider from '@af-common/Common/_components/TranslationProvider';
import tKey from 'Common/tKey';
import useIsSale from '_hooks/useIsSale';


const BlackFriday = styled('div')(({ theme }) => ({
	backgroundColor: '#151744',

	'h2, h3, p': {
		color: '#fff',
		textAlign: 'center',
		maxWidth: '600px',
		padding: '0 1rem',
		'span': {
			color: '#F9AA92',
		}
	},
	'h2': {
		fontSize: '50px',
		margin: '1rem auto 0',
			
		[theme.breakpoints.down('sm')]: {
			fontSize: '35px',
		},
	},
	'h3': {
		fontSize: '26px',
		margin: '0 auto 1rem',
		[theme.breakpoints.down('sm')]: {
			fontSize: '22px',
		},
	},
	'p': {
		fontSize: '18px',
		margin: '1rem auto',
		[theme.breakpoints.down('sm')]: {
			fontSize: '16px',
		},
	},

	'&:before': {
		content: '""',
		display: 'block',
		height: '15vw',
		marginTop: '-2px',
		backgroundImage: 'url(/static/img/pages/home/branding2023/blackfriday-top.svg)',
		backgroundPosition: 'top',
		backgroundRepeat: 'no-repeat',
		backgroundSize: '101%',
	},
	'&:after': {
		content: '""',
		display: 'block',
		height: '15vw',
		marginBottom: '-2px',
		backgroundImage: 'url(/static/img/pages/home/branding2023/blackfriday-bottom.svg)',
		backgroundPosition: 'bottom',
		backgroundRepeat: 'no-repeat',
		backgroundSize: '101%',
	}
}))

const NewFabricAlert = styled('div')(({ theme }) => ({
	padding: '0 20px 50px',
	backgroundColor: '#F3F0FA',
	position: 'absolute',
	width: '200px',
	left: '30px',
	top: '0',
	textAlign: 'center',
	backgroundImage: 'url(/static/img/pages/home/branding2023/new-fabrics/flagbottom.svg)',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center bottom',
	backgroundSize: 'contain',

	[theme.breakpoints.down('md')]: {
		padding: '0 20px 20px',
		borderRadius: '20px',
		backgroundColor: '#F3F0FA',
		width: '100%',
		textAlign: 'center',
		position: 'relative',
		backgroundImage: 'none',
		margin: '80px auto 0',
		left: '0',
	},

	'img': {
		maxWidth: '160px',
	},
	'h2': {
		fontSize: '22px',
		lineHeight: '1',
		fontWeight: '900',
		margin: '0',
		'span' : {
			color: '#F9AA92',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '20px',
		},
	},
	'p': {
		fontSize: '16px',
		fontWeight: '500',
		margin: '0',
		
		[theme.breakpoints.down('md')]: {
			fontSize: '14px',
		},
	},
	'a' : {
		textDecoration: 'none',
		fontWeight: '600',
		color: '#7578B4',
		'&:hover' : {
			color: '#151744',
		}
	},
}))

export default function Home() {
	const [tR] = useTranslation('routes')
	const [t] = useTranslation('pages/home')
	const [igData,setIgData] = useState<{media_type: string, permalink: string, media_url: string}[]>([])

	useEffect(() => {
		const igPromise = makeCancellablePromise(fetch(config.AF_PUBLIC_STATIC + '/cache/instagram_feed.json', { method: 'GET' })
			.then(response => {
				if (!response.ok) throw new Error("An error occurred");
				return response;
			})
			.then(response => response.json()));

		igPromise.promise.then(response => {
			setIgData(response);
		}).catch(reason => { });

		return () => {
			igPromise?.cancel()
		}
	}, [])

	const igContent = useMemo(() => {
		let count = 0;

		return igData.map((post, index) => {
			if (post.media_type != "IMAGE" || count > 11) return null;
			
			count++;
			return <Grid item key={index} xs={4} md={3} lg={2}>
				<div className="home--ig--post">
					<a target="_blank" href={post.permalink}>
						<img src={post.media_url} alt="Instagram Post" />
					</a>
				</div>
			</Grid>
		})
	}, [igData]);

	const isSale = useIsSale()

	return <>
		<Header light={true} borderColor="color1" />
		<div id="home">
			<div id="home--header">
				<Container>
					<Grid container>
						<Grid item md={6} sm={12}>
							<div className="banner-content-wrapper">
								<h1><Trans
									t={t}
									i18nKey={tKey('<up>Print your own</up><span>Custom Fabrics</span> on demand, <br />no minimums!')}
									components={{
										up: <b/>,
										span: <span/>,
										br: <br/>
									}}
								/></h1>
								<Link to={tR('/create')}><Button className="home--cta">{t('Start designing')}</Button></Link>
								<NewFabricAlert>
									<img src="/static/img/pages/home/branding2023/new-fabrics/new-fabric1.png" alt="New Fabrics image" />
									<h2><Trans
											t={t}
											i18nKey={tKey('<span>New</span> Fabrics!')}
											components={{
												span: <span/>
											}}
										/>
									</h2>
									<p><Trans
											t={t}
											i18nKey={tKey('Check out our new <link1>Glitter Performance Knit</link1> and <link2>Vegan Leather</link2>')}
											components={{
												link1: <a href={tR('/fabrics/polyester')+'?f=glitter_performance_knit'} />,
												link2: <a href={tR('/fabrics/vegan-leather')+'?f=crinkle_vegan_leather'} />
											}}
										/>
									</p>
								</NewFabricAlert>
							</div>
						</Grid>
					</Grid>
				</Container>
			</div>

			{ isSale ? <BlackFriday>
				<h2><Trans
						t={t}
						i18nKey={tKey('<span>BOXING DAY</span> IS HERE!')}
						components={{
							span: <span/>
						}}
					/>
				</h2>
				{ /*<h3>{t('We’ve never done a sale this massive before!')}</h3>*/ }
				<p><Trans
						t={t}
						i18nKey={tKey('Get <span>35% off</span> the regular price of ALL OUR FABRICS for 4 days!<br/>From December 26th to 29th.')}
						components={{
							span: <span/>,
							br: <br/>
						}}
					/>
				</p>
			</BlackFriday> : null }

			<div id="home--how-it-works">
				<Container>
					<div id="home--steps-how-it-works-intro">
						<h2>{t('How it works')}</h2>
					</div>
					<div id="home--steps-how-it-works">
						<div className="step-wrapper">
							<img src="/static/img/pages/home/branding2023/one.svg" alt="Step 2 icon" />
							<div className="step-content">
								<h3>{t('Create your design')}</h3>
								<p>{t('Create continuous repeat patterns easily or print images up to 3 meters in length!')}</p>
								<div className="center">
									<Link to={tR('/create')}><Button className="dark">{t('Start designing')}</Button></Link>
									<span>{t('Or')}</span>
									<a href="https://www.youtube.com/@artfabrics/featured" target="_blank" className="btn btn-secondary">{t('Watch Tutorials')}</a>
								</div>
							</div>
						</div>
						<div className="step-wrapper">
							<img src="/static/img/pages/home/branding2023/two.svg" alt="Step 1 icon" />
							<div className="step-content">
								<h3>{t('Choose your fabric')}</h3>
								<p>{t('Permanent prints on a wide range of fabrics. Fabrics for all types of projects.')}</p>
								<div className="center">
									<Link to={tR('/fabrics')}><Button>{t('View Fabrics')}</Button></Link>
									<span>{t('Or')}</span>
									<Link to={tR('/order-sample-pack')} className="btn btn-secondary">{t('Order Sample Packs')}</Link>
								</div>
							</div>
						</div>
						<div className="step-wrapper">
							<img src="/static/img/pages/home/branding2023/three.svg" alt="Step 3 icon" />
							<div className="step-content">
								<h3>{t('Quick Production')}</h3>
								<p><Trans
									t={t}
									i18nKey={tKey('Most orders are printed in 2-6 business days. Fast worldwide shipping from <strong>Montreal, Canada</strong> and <strong>Champlain, New York</strong>.')}
									components={{
										strong: <strong/>
									}}
								/></p>
								<div className="center">
									<Link to={tR('/about-us')} className="btn btn-secondary">{t('About us')}</Link>
								</div>
							</div>
						</div>
					</div>
				</Container>
			</div>

			<div id="home--fabrics-list">
				<Container maxWidth={"lg"}>

					<div id="home--fabrics-list-intro">
						<h2>{t('Fabrics for every project')}</h2>
						<p>{t('Choose from our wide array of fabrics to suit any and every sewing project.')}</p>
						<p>{t('From organic cottons, silk and linen blends, to performance wear stretch fabrics, our ever growing collection of fabrics is intentionally selected with you in mind.')}</p>
						<Link to={tR('/fabrics')}><Button className="dark">{t('Explore our fabrics')}</Button></Link>
					</div>
					<Grid container spacing={3}>
						<Grid item sm={12} md={6} className="fabric-item">
							<div className="img-wrapper">
								<img src="/static/img/pages/home/fabric-pictures/cotton.png" alt={t('Printed cotton fabric')} />
							</div>
							<div className="content-wrapper">
								<h3>{t('Cotton')}</h3>
								<p>{t('Cotton fiber is a soft, natural fiber derived from the cotton plant’s seed fibers, known for its breathability, comfort, and versatility in textiles.')}</p>
								<Link to={tR('/fabrics/cotton')} className="btn btn-secondary">{t('Learn more')}</Link>
							</div>
						</Grid>
						<Grid item sm={12} md={6} className="fabric-item">
							<div className="img-wrapper">
								<img src="/static/img/pages/home/fabric-pictures/silk.png" alt={t('Printed silk fabric')} />
							</div>
							<div className="content-wrapper">
								<h3>{t('Silk')}</h3>
								<p>{t('Silk is a luxurious, smooth fiber produced by silkworms, renowned for its sheen, softness, and strength.')}</p>
								<Link to={tR('/fabrics/silk')} className="btn btn-secondary">{t('Learn more')}</Link>
							</div>
						</Grid>
						<Grid item sm={12} md={6} className="fabric-item">
							<div className="img-wrapper">
								<img src="/static/img/pages/home/fabric-pictures/hemp.png" alt={t('Printed hemp fabric')} />
							</div>
							<div className="content-wrapper">
								<h3>{t('Hemp')}</h3>
								<p>{t('Hemp fiber is a strong, durable, and eco-friendly natural fiber derived from the hemp plant, valued for its versatility and resistance to wear.')}</p>
								<Link to={tR('/fabrics/hemp')} className="btn btn-secondary">{t('Learn more')}</Link>
							</div>
						</Grid>
						<Grid item sm={12} md={6} className="fabric-item">
							<div className="img-wrapper">
								<img src="/static/img/pages/home/fabric-pictures/linen.png" alt={t('Printed polyester fabric')} />
							</div>
							<div className="content-wrapper">
								<h3>{t('Linen')}</h3>
								<p>{t('Linen is a crisp, breathable fiber made from the flax plant, celebrated for its cool, textured feel and natural luster.')}</p>
								<Link to={tR('/fabrics/linen')} className="btn btn-secondary">{t('Learn more')}</Link>
							</div>
						</Grid>
						<Grid item sm={12} md={6} className="fabric-item">
							<div className="img-wrapper">
								<img src="/static/img/pages/home/fabric-pictures/modal.png" alt={t('Printed silk fabric')} />
							</div>
							<div className="content-wrapper">
								<h3>{t('Modal')}</h3>
								<p>{t('Modal is a soft, smooth fiber made from beech tree pulp, known for its luxurious feel, excellent drape, and resistance to shrinkage and fading.')}</p>
								<Link to={tR('/fabrics/modal')} className="btn btn-secondary">{t('Learn more')}</Link>
							</div>
						</Grid>
						<Grid item sm={12} md={6} className="fabric-item">
							<div className="img-wrapper">
								<img src="/static/img/pages/home/fabric-pictures/polyester.png" alt={t('Printed polyester fabric')} />
							</div>
							<div className="content-wrapper">
								<h3>{t('Polyester')}</h3>
								<p>{t('Polyester is a synthetic fiber known for its durability, wrinkle resistance, and ability to retain shape, making it one of the most versatile fibers available today.')}</p>
								<Link to={tR('/fabrics/polyester')} className="btn btn-secondary">{t('Learn more')}</Link>
							</div>
						</Grid>
						<Grid item sm={12} md={6} className="fabric-item">
							<div className="img-wrapper">
								<img src="/static/img/pages/home/fabric-pictures/cashmere.png" alt={t('Printed hemp fabric')} />
							</div>
							<div className="content-wrapper">
								<h3>{t('Cashmere')}</h3>
								<p>{t('Cashmere is an exceptionally soft, luxurious fiber derived from the undercoat of cashmere goats, prized for its warmth, lightweight feel, and refined texture.')}</p>
								<Link to={tR('/fabrics/cashmere')} className="btn btn-secondary">{t('Learn more')}</Link>
							</div>
						</Grid>
						<Grid item sm={12} md={6} className="fabric-item">
							<div className="img-wrapper">
								<img src="/static/img/pages/home/fabric-pictures/vegan-leather.png" alt={t('Vegan Leather fabric')} />
							</div>
							<div className="content-wrapper">
								<h3>{t('Vegan Leather')}</h3>
								<p>{t('Vegan leather is a luxurious, cruelty-free and eco-friendly alternative to traditional leather, crafted from synthetic or plant-based materials with the feel of genuine leather.')}</p>
								<Link to={tR('/fabrics/vegan-leather')} className="btn btn-secondary">{t('Learn more')}</Link>
							</div>
						</Grid>
					</Grid>
				</Container>
			</div>

			<div id="home--everyone">
				<Container>
					<Grid container spacing={3} alignItems="center">
						<Grid item sm={12} md={6}>
							<img src="/static/img/pages/home/branding2023/everyone.png" alt={t('Fabrics for everyone')} />
						</Grid>
						<Grid item sm={12} md={6}>
							<h2>{t('We got professionally printed fabrics for everyone !')}</h2>
							<ul>
								<li><p>{t('Fashion Designers')}</p>{t('Amplify what you can achieve with your designs. Pricing suited for small to medium collections. ')}</li>
								<li><p>{t('Surface Pattern Designers')}</p>{t('Bring your patterns to life on a huge variety of textiles. Create look books and test your prints before production.')}</li>
								<li><p>{t('Small Businesses')}</p>{t('Offer your unique take on the world. Incorporate custom textiles into your products and stand out in the marketplace.')}</li>
								<li><p>{t('Interior Designers')}</p>{t('Experience unparalleled creative flexibility by tailoring patterns and colors to your design themes and clients.')}</li>
								<li><p>{t('Crafters')}</p>{t('Create distinctive, bespoke projects that reflect your style and creativity.')}</li>
							</ul>
						</Grid>
					</Grid>
				</Container>
			</div>

			<div id="home--blocktext">
				<div className="home--blocktext-intro">
					<Container>
						<Grid container spacing={3} alignItems="center">
							<Grid item xs={12} sm={7} md={6}>
								<h2>{t('Accessing high quality printed fabrics has never been this easy!')}</h2>
								<p>{t('Create layouts, design patterns, and only order what you need. Printing your own fabrics invites a world of creativity, sustainability, and profability for you to discover.')}</p>
								<p>{t('Welcome to our our creative community where anyone can design and print fabrics at affordable prices.')}</p>
								<Link to={tR('/order-sample-pack')}><Button className="dark">{t('Get your sample pack')}</Button></Link>
							</Grid>
							<Grid item xs={12} sm={5} md={6} className="right">
								<img src="/static/img/pages/home/branding2023/high-quality.png" alt={t('Fabrics for everyone')} />
							</Grid>
						</Grid>
					</Container>
				</div>

				<div className="home--blocktext-content">
					<Container>
						<div className="home--boxes-wrapper">
							<a target="_blank" href="https://blog.artfabrics.com/introducing-the-colour-chart/" className="home--boxes">
								<div className="home--boxes-image"></div>
								<div className="home--boxes-content">
									<h3>{t('Color charts for your design process')}</h3>
									<p>{t('With over 750 color patches, designing with a color chart helps you achieve specific colors in your fabric prints')}</p>
									<p className="fakelink">{t('Learn more')}</p>
								</div>
							</a>
							<a target="_blank" href="https://blog.artfabrics.com/design-lab-tutorials/" className="home--boxes">
								<div className="home--boxes-image"></div>
								<div className="home--boxes-content">
									<h3>{t('Need help designing?')}</h3>
									<p>{t('We have tutorials to help you get started with creating your first designs')}</p>
									<p className="fakelink">{t('Learn more')}</p>
								</div>
							</a>
						</div>
						<div className="home--boxes-wrapper right">
							<a target="_blank" href="https://blog.artfabrics.com/reactive-printing-what-to-expect-and-limitations-for-our-process/" className="home--boxes">
								<div className="home--boxes-image"></div>
								<div className="home--boxes-content">
									<h3>{t('A guide for prints on natural fabrics')}</h3>
									<p>{t('Everything you need to know about reactive printing on natural fabrics.')}</p>
									<p className="fakelink">{t('Learn more')}</p>
								</div>
							</a>
							<a target="_blank" href="https://blog.artfabrics.com/oeko-tex-and-ecopolyester-fabrics-find-out-more-about-what-these-mean/" className="home--boxes">
								<div className="home--boxes-image"></div>
								<div className="home--boxes-content">
									<h3><Trans
										t={t}
										i18nKey={tKey('All about <br />OEKO-TEX and Ecopolyester')}
										components={{
											br: <br/>
										}}
									/></h3>
									<p>{t('Get to know our fabric certifications and what they mean.')}</p>
									<p className="fakelink">{t('Learn more')}</p>
								</div>
							</a>
						</div>
					</Container>
				</div>
			</div>

			{ /* <div id="home--testimonies">
					<Container>
						<Grid container spacing={3}>
							<Grid className="testimony-wrapper" item xs={12} md={4}>
								<div className="testimony--box">
									<img src="http://placekitten.com/150/150" />
									<h4>“Outstanding customer service!!! They have answered all of my questions with so much patience and kindness.”</h4>
									<p className="testimony--author">- Elian Israeli</p>
								</div>
							</Grid>
							<Grid className="testimony-wrapper" item xs={12} md={4}>
								<div className="testimony--box">
									<img src="http://placekitten.com/150/150" />
									<h4>“Excellent products and attentive service!”</h4>
									<p className="testimony--author">- Anne-Marie Biron</p>
								</div>
							</Grid>
							<Grid className="testimony-wrapper" item xs={12} md={4}>
								<div className="testimony--box">
									<img src="http://placekitten.com/150/150" />
									<h4>“Amazing assortment of patterns and materials!”</h4>
									<p className="testimony--author">- Matthew Stone</p>
								</div>
							</Grid>
						</Grid>
					</Container>
				</div> */ }

			<div id="home--ig">
				<Container>
					<h3>{t('Follow our journey!')}</h3>
					<a href="https://www.instagram.com/artfabrics/" target="_blank">
						<Button>
							<img src="/static/img/instagram.png" alt="Instagram" />
							ARTFABRICS
						</Button>
					</a>

					<Grid container spacing={2}>
						{igContent}
					</Grid>
				</Container>
			</div>
		</div>

		<Footer />
	</>
}